<template>
	<div class="google-auth">

		<h1>Подключение аккаунта Google</h1>

		<p class="mt-4">Подключите свой Google-аккаунт к системе, чтобы экспортировать КП в Google Таблицы.</p>

		<v-btn class="mt-4" :loading="loading" color="primary" @click="submit">
			Подключить аккаунт
			<v-icon class="ml-2" small>mdi-google</v-icon>
		</v-btn>

	</div>
</template>

<script>
export default {
	name: "google-auth",
	data() {
		return {
			dialog: false,
			loading: false,
		}
	},
	computed: {},
	watch: {},
	methods: {

		submit() {
			this.loading = true;

			this.$store.dispatch('post', {
				action: 'GoogleAuthController',
				params: {
					successUrl: window.location.protocol + "//" + window.location.host + "/settings/google/auth/confirmation"
					//successUrl: "http://vitrunet-kp.ai.dev.playnext.net/settings/google/auth/confirmation"
				}
			}).then((res) => {
				//this.$router.push({name: 'order', params: {id: this.orderId}});
				//this.$router.push(res?.page?.GoogleAuthController?.authUrl);
				window.location.href = res?.page?.GoogleAuthController?.authUrl;
			}).catch((error) => {
				console.log("ERROR: ", error);
			}).finally(() => {
				//this.loading = false;
			})
		},

	},
	mounted() {

	}
}

</script>

<style lang="scss">

.google-auth {
}
</style>
